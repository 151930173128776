import { createContext, Dispatch } from 'react';

export const ProjectsStateContext = createContext({
  state: {} as ProjectListState,
  dispatch: {} as Dispatch<ReducerAction>,
});

export interface IProject {
  id: number;
  name: string;
  appKey: string;
  platform: string;
  bundle: string;
  company: string;
  china: boolean;
  storeTitle: string;
  status: string;
  buildMachine: boolean;
  experiments: boolean;
  _filter: string;
}

export interface ProjectListState {
  projects: IProject[];
  gamepackList: IGamepack[];
  needToResizeLimit: boolean;
  viewProjectsLimit: number;

  companies: string[];

  searchFilter: string;

  showRequestModal: boolean;
  showAddNewProjectModal: boolean;

  blacklistedSayKitGamesCount: number;
  blacklistedUnityGamesCount: number;
}

export interface IGamepack {
  Key: string;
  Title: string;
  IosAppKey: string;
  AndroidAppKey: string;
  ChinaIosAppKey: string;
  ChinaAndroidAppKey: string;
  AmazonAppKey: string;
  WebAppKey: string;
  HuaweiAppKey: string;
  XiaomiAppKey: string;
  SteamAppKey: string;
  SayStoreAppKey: string;
  CrazyGamesAppKey: string;
  PokiAppKey: string;
  YoutubeAppKey: string;
  AllAppKeys: string[];
  _filter: string;
}

export enum reducerActionType {
  SET_PROJECTS,
  SET_GAMEPACK_LIST,
  SET_NEED_TO_RESIZE_VIEW_LIMIT,
  SET_VIEW_PROJECTS_LIMIT,
  SET_COMPANIES,
  SET_SEARCH_FILTER,
  SET_SHOW_REQUEST_MODAL,
  SET_SHOW_ADD_NEW_PROJECT_MODAL,
  SET_BLACKLISTED_SAYKIT_GAMES_COUNT,
  SET_BLACKLISTED_UNITY_GAMES_COUNT,
}

export interface ReducerAction {
  type: reducerActionType;
  payload?: any;
}
