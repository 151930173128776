import {
  IGamepack,
  IProject,
  ProjectListState,
  ReducerAction,
  reducerActionType,
} from './models';

export const DEFAULT_LIMIT_SIZE = 100;

export const initialReducerState = () => {
  return {
    projects: [] as IProject[],
    gamepackList: [] as IGamepack[],
    needToResizeLimit: false,
    viewProjectsLimit: DEFAULT_LIMIT_SIZE,
    companies: [] as string[],
    blacklistedSayKitGamesCount: 0,
    blacklistedUnityGamesCount: 0,
  } as ProjectListState;
};

export const reducer = (prevState: ProjectListState, action: ReducerAction) => {
  switch (action.type) {
    case reducerActionType.SET_PROJECTS:
      return { ...prevState, projects: action.payload };

    case reducerActionType.SET_GAMEPACK_LIST:
      return { ...prevState, gamepackList: action.payload };

    case reducerActionType.SET_NEED_TO_RESIZE_VIEW_LIMIT:
      return { ...prevState, needToResizeLimit: action.payload };

    case reducerActionType.SET_VIEW_PROJECTS_LIMIT:
      return { ...prevState, viewProjectsLimit: action.payload };

    case reducerActionType.SET_COMPANIES:
      return { ...prevState, companies: action.payload };

    case reducerActionType.SET_SEARCH_FILTER:
      return { ...prevState, searchFilter: action.payload };

    case reducerActionType.SET_SHOW_REQUEST_MODAL:
      return { ...prevState, showRequestModal: action.payload };

    case reducerActionType.SET_SHOW_ADD_NEW_PROJECT_MODAL:
      return { ...prevState, showAddNewProjectModal: action.payload };

    case reducerActionType.SET_BLACKLISTED_SAYKIT_GAMES_COUNT:
      return { ...prevState, blacklistedSayKitGamesCount: action.payload };

    case reducerActionType.SET_BLACKLISTED_UNITY_GAMES_COUNT:
      return { ...prevState, blacklistedUnityGamesCount: action.payload };
    default:
      return prevState;
  }
};

export const filterProjects = (
  filter: string,
  projects: IProject[]
): IProject[] => {
  if (!filter) {
    return projects;
  }
  const parts = filter.trim().toLowerCase().split(/\s+/);

  return projects.filter((p) => {
    for (let i = 0; i < parts.length; i++) {
      if (p._filter.indexOf(parts[i]) === -1) {
        return false;
      }
    }
    return true;
  });
};

export const makeProjectsFilterField = (projects: IProject[]): IProject[] => {
  return projects.map((project) => {
    project._filter =
      project.id +
      ' ' +
      project.appKey.toLowerCase() +
      ' ' +
      project.name.toLowerCase() +
      ' ' +
      project.bundle.toLowerCase() +
      ' ' +
      project.company.toLowerCase() +
      ' ' +
      project.storeTitle.toLowerCase();
    return project;
  });
};

export const makeGamepackFilterField = (
  gamepackList: IGamepack[]
): IGamepack[] => {
  return gamepackList.map((gp) => {
    gp._filter = gp.Key.toLowerCase() + ' ' + gp.Title.toLowerCase();

    if (gp.IosAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.IosAppKey.toLowerCase();
    }
    if (gp.AndroidAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.AndroidAppKey.toLowerCase();
    }
    if (gp.ChinaIosAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.ChinaIosAppKey.toLowerCase();
    }
    if (gp.ChinaAndroidAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.ChinaAndroidAppKey.toLowerCase();
    }
    if (gp.AmazonAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.AmazonAppKey.toLowerCase();
    }
    if (gp.WebAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.WebAppKey.toLowerCase();
    }
    if (gp.HuaweiAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.HuaweiAppKey.toLowerCase();
    }
    if (gp.XiaomiAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.XiaomiAppKey.toLowerCase();
    }
    if (gp.SteamAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.SteamAppKey.toLowerCase();
    }
    if (gp.SayStoreAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.SayStoreAppKey.toLowerCase();
    }
    if (gp.CrazyGamesAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.CrazyGamesAppKey.toLowerCase();
    }
    if (gp.PokiAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.PokiAppKey.toLowerCase();
    }
    if (gp.YoutubeAppKey.toLowerCase() !== '') {
      gp._filter += ' ' + gp.YoutubeAppKey.toLowerCase();
    }
    if (gp.AllAppKeys.join(' ').toLowerCase() !== '') {
      gp._filter += ' ' + gp.AllAppKeys.join(' ').toLowerCase();
    }
    return gp;
  });
};

export const gamepackAppkeyList = (gp: IGamepack) => {
  let list = [] as string[];
  if (gp.IosAppKey.toLowerCase() !== '') {
    list.push(gp.IosAppKey);
  }
  if (gp.AndroidAppKey.toLowerCase() !== '') {
    list.push(gp.AndroidAppKey);
  }
  if (gp.ChinaIosAppKey.toLowerCase() !== '') {
    list.push(gp.ChinaIosAppKey);
  }
  if (gp.ChinaAndroidAppKey.toLowerCase() !== '') {
    list.push(gp.ChinaAndroidAppKey);
  }
  if (gp.AmazonAppKey.toLowerCase() !== '') {
    list.push(gp.AmazonAppKey);
  }
  if (gp.WebAppKey.toLowerCase() !== '') {
    list.push(gp.WebAppKey);
  }
  if (gp.HuaweiAppKey.toLowerCase() !== '') {
    list.push(gp.HuaweiAppKey);
  }
  if (gp.XiaomiAppKey.toLowerCase() !== '') {
    list.push(gp.XiaomiAppKey);
  }
  if (gp.SteamAppKey.toLowerCase() !== '') {
    list.push(gp.SteamAppKey);
  }
  if (gp.SayStoreAppKey.toLowerCase() !== '') {
    list.push(gp.SayStoreAppKey);
  }
  if (gp.CrazyGamesAppKey.toLowerCase() !== '') {
    list.push(gp.CrazyGamesAppKey);
  }
  if (gp.PokiAppKey.toLowerCase() !== '') {
    list.push(gp.PokiAppKey);
  }
  if (gp.YoutubeAppKey.toLowerCase() !== '') {
    list.push(gp.YoutubeAppKey);
  }
  if (gp.AllAppKeys.length > 0) {
    list.push(...gp.AllAppKeys);
  }

  return list;
};

export const filterGamepackList = (
  filter: string,
  gamepackList: IGamepack[]
): IGamepack[] => {
  if (!filter) {
    return gamepackList;
  }
  const parts = filter.trim().toLowerCase().split(/\s+/);

  return gamepackList.filter((p) => {
    for (let i = 0; i < parts.length; i++) {
      if (p._filter.indexOf(parts[i]) === -1) {
        return false;
      }
    }
    return true;
  });
};

export const areFirstTwoWordsDiff = (title1: string, title2: string) => {
  const getFirstTwoWords = (title: string) => {
    return title.trim().split(/\s+/).slice(0, 2).join(' ').toLowerCase();
  };

  return getFirstTwoWords(title1) !== getFirstTwoWords(title2);
};
